.cardWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: auto 0;
}

.topSection {
  border-radius: 16px 16px 0 0;
  display: flex;
  min-height: 160px;
  width: 100%;
  flex-direction: column;
  color: #fff;
  text-transform: uppercase;
  justify-content: start;
  font:
    700 36px Barlow,
    sans-serif;
}

.card {
  justify-content: center;
  border-radius: 16px 16px 0 0;
  border: 1px solid #424242;
  border-bottom: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  min-height: 190px;
  width: 100%;
  flex-direction: column;
  padding: 0px 24px;
}

.blue {
  background-color: #315570;
}

.maroon {
  background-color: #40252c;
}

.iconContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.categoryIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 86px;
}

.categoryTitle {
  margin-top: 12px;
}

.bottomSection {
  border-radius: 0 0 16px 16px;
  border: 1px solid #424242;
  border-top: none;
  background: rgba(53, 53, 53, 0.5);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  height: 300px;
  width: 100%;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.categoriesList {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font:
    600 24px/32px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.categoryItem {
  margin-top: 8px;
}

.categoryItem:first-child {
  margin-top: 0;
}

@media (max-width: 991px) {
  .card {
    padding: 0 20px;
  }

  .bottomSection {
    padding: 0 20px;
  }
}
