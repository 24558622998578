.donutWrapper {
  position: relative;
  width: 623px;
  height: 550px;
  background-image: url('https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa93fb25f4c6447a4a4783b7433288994');
  background-size: cover;
  background-position: center;
}

.donutLeft,
.donutRight,
.donutCenter {
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.donutLeft {
  left: 5px;
  top: 0;
  height: 550px;
  width: 305px;
}

.donutRight {
  right: 0;
  top: 0;
  height: 550px;
  width: 298px;
}

.donutCenter {
  left: 147px;
  top: 110.5px;
  width: 329px;
  height: 329px;
  border-radius: 50%;
  z-index: 1;
}

.donutLeft img,
.donutRight img,
.donutCenter img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  -webkit-user-drag: none;
}

.donutLeft:hover img,
.donutRight:hover img,
.donutCenter:hover img {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}
