.background {
  width: 100vw;
  background-image: url('https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbb754a1e70fb4df1ade52f74e4c2e161');
  background-size: cover;
  background-position: 0 -150px;
  background-repeat: no-repeat;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 110px auto 160px;
  width: 1224px;
}

.mainContent {
  width: 100%;
  display: flex;
  margin-top: 165px;
}

.imageContainer {
  margin-top: -125px;
}

.featureSection {
  align-items: center;
  border-radius: 16px;
  border: 1px solid #424242;
  background: rgba(53, 53, 53, 0.5);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  margin-top: 150px;
  width: 100%;
  flex-direction: column;
  padding: 45px 24px;
}

.featureGrid {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 6px;
  justify-content: center;
  flex-wrap: wrap;
}

.divider {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.12);
  margin: 10px 0 35px 0;
}

.buttonGroup {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: start;
  gap: 29px;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .mainContent {
    flex-direction: column;
    margin-top: 40px;
  }

  .featureSection {
    margin-top: 40px;
    padding: 20px;
  }

  .navContent {
    padding: 16px 20px;
  }
}
