.primary {
  border-radius: 50px;
  background-color: #504b21;
  color: #f4da02;
  width: 350px;
  margin: auto 0;
  padding: 11px 0;
  border: 3px solid #f5df42;
  text-transform: uppercase;
  font:
    700 24px Barlow,
    sans-serif;
  cursor: pointer;
}

.secondary {
  border-radius: 50px;
  border: 3px solid #535353;
  background-color: #535353;
  width: 350px;
  margin: auto 0;
  padding: 11px 0;
  color: #9a9a9a;
  text-transform: uppercase;
  font:
    700 24px Barlow,
    sans-serif;
  cursor: pointer;
}

.secondary:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 991px) {
  .primary,
  .secondary {
    padding: 0 20px;
  }
}
