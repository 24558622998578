.hero {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 550px;
  margin-top: -65px;
  align-items: start;
  justify-content: space-between;
  padding-top: 105px;
}

@media (max-width: 991px) {
  .hero {
    max-width: 100%;
    padding: 100px 20px 0;
  }
}

.heroBackground {
  position: absolute;
  inset: 0;
  height: 550px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.4;
}

.heroContent {
  position: relative;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  justify-content: start;
  margin: 0 auto;
  width: 1224px;
}

.heroTitle {
  color: #fff;
  text-transform: uppercase;
  font:
    900 92px/82px Barlow,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  width: 1000px;
}

@media (max-width: 991px) {
  .heroTitle {
    max-width: 100%;
    font-size: 40px;
  }
}

.heroDescription {
  color: #fff;
  font:
    600 36px/36px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  margin-top: -65px;
  width: 590px;
}

.highlightedText {
  color: #f6e003;
}

@media (max-width: 991px) {
  .heroDescription {
    max-width: 100%;
  }
}
