.chartCard {
  border-radius: 16px;
  background-color: #1d1d1d;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font:
    700 36px/1 Barlow,
    sans-serif;
}

.chartTitle {
  padding: 20px 0;
  margin: 0;
}

.divider {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin: 0 auto;
}

.chartContainer {
  margin: 10px 0 60px;
}

.chartImage {
  height: 500px;
  object-fit: contain;
}

.footerNote {
  font:
    500 18px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  color: #fff;
  text-transform: none;
  margin-bottom: -35px;
}

.underline {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .chartCard {
    max-width: 100%;
  }

  .chartHeader {
    padding: 0 20px;
  }

  .chartContent {
    max-width: 100%;
    padding: 0 20px;
  }
}
