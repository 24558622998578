.container {
  display: flex;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 1224px;
}

.statContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 0;
}

.headline {
  color: #fff;
  font:
    600 36px/40px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.amount {
  color: #ffe500;
  text-transform: uppercase;
  font:
    900 100px/1 Barlow,
    sans-serif;
  margin-top: 20px;
}

.chartSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.chartColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.chartImage {
  object-fit: contain;
  object-position: top;
  width: 87%;
  margin-top: -40px;
}

.source {
  color: #d9d9d9;
  text-align: center;
  align-self: center;
  font:
    italic 500 14px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .statContent {
    max-width: 100%;
  }

  .headline {
    max-width: 100%;
  }

  .amount {
    max-width: 100%;
    font-size: 40px;
  }

  .chartSection {
    max-width: 100%;
  }

  .chartColumn {
    width: 100%;
  }

  .chartImage {
    margin-top: 40px;
  }
}
