.themeSection {
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 600;
  margin: 0 auto;
  width: 1224px;
}

.header {
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.subheading {
  color: #f6e003;
  font-size: 24px;
}

.heading {
  color: #fff;
  font-size: 48px;
  line-height: 1;
  margin-top: 10px;
}

.themeGroup {
  display: flex;
  margin-top: 30px;
  gap: 20px;
  font-size: 20px;
}

@media (max-width: 991px) {
  .heading {
    max-width: 100%;
    font-size: 40px;
  }

  .themeGroup {
    max-width: 100%;
  }
}
