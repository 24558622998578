.background {
  width: 100%;
  background: linear-gradient(180deg, #14232f 0%, rgba(0, 0, 0, 0) 23.47%);
}

.container {
  display: flex;
  flex-direction: column;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 auto;
  width: 1224px;
}

.header {
  margin-top: 100px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.divider {
  background-color: #f6e003;
  min-height: 4px;
  width: 270px;
  max-width: 100%;
  border: 4px solid #f6e003;
}

.title {
  text-align: center;
  align-self: stretch;
  margin-top: 30px;
  width: 100%;
  gap: 12px;
  font-size: 48px;
  color: #fff;
}

.cardGrid {
  display: flex;
  margin-top: 40px;
  width: 100%;
  max-width: 1224px;
  align-items: center;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .container {
    padding: 100px 20px 0;
  }

  .header {
    max-width: 100%;
  }

  .title {
    max-width: 100%;
    font-size: 40px;
  }

  .cardGrid {
    max-width: 100%;
  }
}
