.featureCard {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  width: 267px;
  margin: auto 0;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 130px;
  align-self: center;
  max-width: 100%;
}

.content {
  display: flex;
  margin-top: 14px;
  height: 165px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.title {
  color: #f6e003;
  text-transform: uppercase;
  font:
    700 21px Barlow,
    sans-serif;
  text-align: center;
  margin: 0 5px;
}

.description {
  color: #fff;
  margin-top: 12px;
  font:
    500 18px/22px Work Sans,
    sans-serif;
  max-width: 248px;
  text-align: center;
}
