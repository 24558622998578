.tableContainer {
  background: rgba(29, 29, 29, 1);
  border-radius: 16px;
  padding: 40px;
}

.table {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  color: #f6e003;
  font-size: 20px;
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 600;
}

.basketTableTitle,
.basketTitle {
  font-size: 20px;
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  color: #fff;
}

.basketTableTitle {
  font-size: 24px;
}

.nonAlcIcon {
  margin-bottom: 20px;
}

.iconTableWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.iconTitleWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-right: 20px;
}

.columnStats {
  display: flex;
  width: 120px;
  justify-content: space-between;
}

.divider {
  height: 1px;
  background: #fff;
  width: 100%;
}

.tableRow {
  display: flex;
  align-items: center;
  color: #fff;
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 600;
}

.categoryName {
  width: 240px;
  font-size: 20px;
}

.categoryStats {
  display: flex;
  width: 120px;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.percentage {
  color: #fff;
}

.index {
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
}

@media (max-width: 991px) {
  .tableContainer {
    padding: 20px;
  }
}
