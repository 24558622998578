.profileCard {
  border-radius: 16px;
  background: #1d1d1d;
  display: flex;
  gap: 10px;
  flex: 1;
  padding: 30px 0;
  min-width: 602px;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 235px;
}

.imageContainer {
  border-radius: 200px;
  position: relative;
  height: 200px;
  width: 200px;
}

.image {
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  color: #fff;
  text-transform: uppercase;
  font:
    700 48px/1 Barlow,
    sans-serif;
  margin: 0;
  padding-bottom: 15px;
}

.description {
  color: #fff;
  margin: 0;
  font:
    500 18px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.relationshipStatus {
  color: #fff;
  font:
    500 18px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.drinkerType {
  color: #b977d1;
  margin: 0;
  font:
    500 18px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.description,
.drinkerType,
.relationshipStatus {
  height: 10px;
}

@media (max-width: 991px) {
  .name {
    font-size: 40px;
  }
}
