.hero {
  position: relative;
  max-height: 430px;
  width: 100%;
  margin: 0 auto;
}

.heroImage {
  width: 100%;
  max-height: 430px;
  object-fit: cover;
  opacity: 0.3;
}

.heroContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 1224px;
  margin: 0 auto;
}

.heroTitle {
  color: #fff;
  font-family: Barlow, sans-serif;
  font-size: 80px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
}

.heroDescription {
  color: #fff;
  font-family: 'Work Sans', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  width: 790px;
}

.stats {
  display: flex;
  gap: 16px;
  margin-bottom: 25px;
}

@media (max-width: 991px) {
  .heroTitle {
    font-size: 40px;
  }

  .heroContent {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
