.card {
  display: flex;
  flex-direction: column;
  min-height: 380px;
  width: 100%;
  text-align: center;
}

.cardTop {
  border-radius: 16px 16px 0 0;
  display: flex;
  padding: 6px 25px 12px;
  color: #fff;
  text-transform: uppercase;
  font:
    700 24px/24px Barlow,
    sans-serif;
  align-items: center;
  justify-content: center;
}

.imgCopy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 124px;
}

.img {
  height: 75px;
  object-fit: contain;
}

.cardBtm {
  border-radius: 0 0 16px 16px;
  background-color: rgba(57, 57, 57, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 15px;
  flex: 1;
}

.stats {
  display: flex;
  gap: 15px;
  margin-bottom: -8px;
}

.stat {
  border-radius: 4px;
  display: flex;
  height: 58px;
  width: 65px;
  color: #fff;
  justify-content: center;
  padding: 14px 4px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.percentage {
  font:
    700 24px/12px Barlow,
    sans-serif;
  text-transform: uppercase;
}

.label {
  margin-top: 6px;
  font:
    500 18px Work Sans,
    sans-serif;
}

.copy {
  color: #fff;
  font:
    400 14px/18px Inter,
    sans-serif;
  padding: 0px;
}

.prefix,
.suffix {
  font-weight: 500;
}

.highlight {
  font-weight: 700;
}

@media (max-width: 991px) {
  .cardTop {
    padding: 20px;
  }
}

.cardButton {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  transition: transform 0.2s ease;
}

.cardButton:hover {
  transform: scale(1.02);
}
