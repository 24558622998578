.card {
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  white-space: nowrap;
  width: 120px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image {
  object-fit: contain;
  aspect-ratio: 1;
  width: 120px;
}

.title {
  align-self: center;
  margin-top: 1.25rem;
}
