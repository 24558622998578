.container {
  display: flex;
  align-items: center;
  gap: 18px 0;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.navigationSection {
  display: flex;
  height: 170px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.mainTitle {
  color: #fff;
  font-size: 48px;
  margin-bottom: 25px;
}

.tabContainer {
  border-radius: 65px;
  background-color: rgba(83, 83, 83, 1);
  display: flex;
  width: 650px;
  font-size: 24px;
}

.chartImage {
  object-fit: contain;
  object-position: center;
  width: 1340px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.45);
  height: auto;
  margin: 10px auto;
}

@media (max-width: 991px) {
  .navigationSection {
    max-width: 100%;
  }

  .mainTitle {
    max-width: 100%;
    font-size: 40px;
  }

  .tabContainer {
    margin-top: 40px;
  }

  .chartImage {
    max-width: 100%;
  }
}
