.card {
  border-radius: 16px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex: 1;
  margin: auto 0;
  padding: 10px 10px;
  width: 100%;
}

.buttonWrap {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  flex: 1;
  flex-basis: 0%;
  display: flex;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.buttonWrap:hover {
  transform: scale(1.05);
}

.cardContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.cardIcon {
  object-fit: contain;
  object-position: center;
  align-self: center;
}

.cardText {
  font-size: 24px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
}

@media (max-width: 991px) {
  .card {
    padding: 0 20px;
  }
}
