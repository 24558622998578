.downloadItem {
  display: flex;
  min-height: 104px;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.content {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: auto 0;
}

.title {
  text-transform: uppercase;
  font:
    700 28px/1 Barlow,
    sans-serif;
}

.description {
  font-feature-settings: 'salt' on;
  margin-top: 4px;
  font:
    500 16px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.buttonWrap {
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  color: #fff;
}

.icon:hover {
  transform: scale(1.1);
}

@media (max-width: 991px) {
  .downloadItem {
    max-width: 100%;
  }
}
