.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 60px;
  width: 100vw;
  margin: 60px 0;
}

.title {
  color: #fff;
  text-align: center;
  font:
    600 32px Work Sans,
    sans-serif;
  padding: 0 20px;
}

.priceTiersTable {
  width: 575px;
  margin-top: -30px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}
