.header {
  border-radius: 16px 16px 0 0;
  background-color: #f5df42;
  display: flex;
  height: 75px;
  color: #2e2e2e;
  text-transform: uppercase;
  justify-content: center;
  font:
    700 36px/1 Barlow,
    sans-serif;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
    padding: 0 20px;
  }
}

.titleWrapper {
  display: flex;
  gap: 12px;
}

@media (max-width: 991px) {
  .titleWrapper {
    max-width: 100%;
  }
}

.icon {
  width: 52px;
  margin: auto 0;
}

.title {
  margin: auto 0;
}

.highlightedText {
  color: #f5df42;
}

.whiteText {
  color: #fff;
}
