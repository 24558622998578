.testimonialCard {
  all: unset;
  box-sizing: border-box;
  cursor: grab;
  border-radius: 16px;
  display: flex;
  min-width: 600px;
  overflow: hidden;
  justify-content: start;
  flex-wrap: wrap;
  width: 600px;
  height: 300px;
  user-select: none;
  -webkit-user-drag: none;
}

.quoteContent {
  background-color: #1d1d1d;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  flex: 1;
  flex-basis: 0%;
  padding: 24px 20px;
}

.quoteIcon {
  aspect-ratio: 1.13;
  object-fit: contain;
  object-position: center;
  width: 34px;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
}

.quoteText {
  color: #fff;
  font:
    600 20px/130% Inter,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-style: italic;
  margin: 0;
}

.authorInfo {
  color: #fff;
}

.authorName {
  text-transform: uppercase;
  font:
    700 24px Barlow,
    sans-serif;
  margin: 0;
}

.authorDetails {
  display: flex;
  margin-top: 4px;
  align-items: center;
  gap: 8px;
  font:
    500 16px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.divider {
  width: 0;
  height: 22px;
  border: 1px solid #fff;
}

.imageWrapper {
  aspect-ratio: 1;
  width: 100%;
  min-width: 240px;
  flex: 1;
  flex-basis: 48px;
}

.testimonialImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
}

@media (max-width: 991px) {
  .testimonialCard {
    max-width: 100%;
  }

  .quoteContent {
    padding: 20px;
  }

  .quoteText {
    margin: 40px 0;
  }
}
