.card {
  border-radius: 16px;
  display: flex;
  min-width: 240px;
  min-height: 480px;
  flex-direction: column;
  justify-content: start;
  padding: 20px;
}

.cardImage {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  max-width: 235px;
  border-radius: 0;
  align-self: center;
  margin-bottom: -25px;
}

.content {
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.titleWrapper {
  display: flex;
  width: 100%;
  padding-bottom: 6px;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  white-space: nowrap;
  text-transform: uppercase;
  justify-content: center;
}

.divider {
  background-color: #f6e003;
  align-self: stretch;
  width: 16px;
  height: 1px;
  margin: auto 0;
  border: 1px solid #f6e003;
}

.title {
  align-self: stretch;
  margin: auto 0;
}

.number {
  color: #f6e003;
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
}

.description {
  width: 90%;
  text-align: center;
  margin: auto;
  font:
    500 18px/26px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .titleWrapper {
    white-space: initial;
  }
}
