.card {
  position: relative;
  display: flex;
  min-height: 260px;
  flex-direction: column;
  flex-grow: 1;
  margin: 10px 0;
  padding: 30px 40px;
}

.narrow {
  max-width: 420px;
}

.wide {
  max-width: 620px;
}

.cardBackground {
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  background: rgba(255, 255, 255, 0.16);
  position: absolute;
  inset: 0;
  z-index: 0;
}

.icon {
  aspect-ratio: 1.17;
  object-fit: contain;
  width: 48px;
  z-index: 0;
}

.quote {
  font:
    italic 600 26px/30px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  z-index: 0;
  margin: auto 0;
}

@media (max-width: 991px) {
  .card {
    max-width: 100%;
    min-height: 300px;
    padding: 20px 20px;
  }

  .quote {
    max-width: 100%;
  }
}
