.header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-bottom: 2.5rem;
}

.line {
  flex: 1;
  flex-shrink: 1;
  align-self: stretch;
  margin: auto 0;
  height: 1px;
  background-color: #fbbf24;
  border: 1px solid #fbbf24;
  flex-basis: 0;
}

.title {
  align-self: stretch;
  margin: auto 0;
}
