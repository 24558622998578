.section {
  width: 1224px;
  margin: 0 auto;
}

.sectionTitle {
  width: 1200px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.subtitle {
  color: #f6e003;
  font-size: 24px;
}

.title {
  color: #fff;
  font-size: 48px;
  line-height: 1;
  margin-top: 14px;
}

.factorsSection {
  display: flex;
  margin-top: 30px;
  gap: 20px;
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 600;
}

@media (max-width: 991px) {
  .subtitle {
    max-width: 100%;
  }

  .title {
    max-width: 100%;
    font-size: 40px;
  }

  .factorsSection {
    max-width: 100%;
  }
}
