.about {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  padding: 24px 0;
  background-color: rgba(53, 53, 53, 0.5);
  border-radius: 16px;
  border: 1px solid #424242;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  margin: 50px auto;
  width: 1224px;
  min-height: 360px;
}

.aboutContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 624px;
  padding: 0 0px 0 30px;
}

.aboutTitle {
  font-family: 'Barlow', sans-serif;
  font-size: 70px;
  font-weight: 800;
  line-height: 63px;
  width: 624px;
  text-transform: uppercase;
  margin: 0;
}

.aboutText {
  color: #ffffff;
  font-family: 'Work Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  margin: 0;
  width: 550px;
}

.aboutGuidelines {
  color: #ffffff;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  margin: 0;
}

.subcatCansWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0;
}

.subcatCans {
  height: auto;
  width: 485px;
  object-fit: contain;
  transition: transform 0.2s ease;
  padding-right: 44px;
}

.subcatCans:hover {
  transform: scale(1.05);
}

@media (prefers-reduced-motion: reduce) {
  .subcatCans {
    transition: none;
  }
}

@media (max-width: 991px) {
  .about {
    padding: 32px 16px;
    gap: 32px;
  }

  .aboutTitle {
    font-size: 40px;
  }

  .aboutText {
    font-size: 20px;
  }

  .subcatCans {
    height: 80px;
  }

  .subcatCansWrap {
    gap: 16px;
  }
}

@media (max-width: 480px) {
  .aboutTitle {
    font-size: 32px;
  }

  .aboutText {
    font-size: 18px;
  }

  .subcatCans {
    height: 60px;
  }
}
