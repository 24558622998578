.container {
  align-items: center;
  background: linear-gradient(160deg, rgba(246, 224, 3, 0.25) -18.26%, rgba(0, 0, 0, 0.25) 90.85%);
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 60px;
  width: 100vw;
  padding: 80px 0 30px;
  margin-bottom: 60px;
}

.heading {
  color: #fff;
  text-align: center;
  font:
    600 32px/32px Work Sans,
    sans-serif;
  margin-top: -10px;
  padding: 0 20px;
  width: 800px;
}

.alcoholCategoriesChart {
  width: 1015px;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}
