.personaSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #1d1d1d;
  border-radius: 16px;
}

.personaHeader {
  border-radius: 16px 16px 0px 0px;
  background-color: #1d1d1d;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 20px 0 0;
  font:
    700 24px Barlow,
    sans-serif;
}

.divider {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  margin-top: 20px;
}

.personaGrid {
  flex: 1;
  border-radius: 0px 0px 16px 16px;
  background-color: #1d1d1d;
  padding: 22px 30px;
  background-image: url('https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff475ffeadb0e4d89bf26c783a29ee322');
  background-size: 95%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.outgoingFamiliar {
  position: absolute;
  top: 20px;
  left: 60px;
}

.outgoingExploration {
  position: absolute;
  top: 20px;
  right: 60px;
}

.reservedFamiliar {
  position: absolute;
  bottom: 60px;
  left: 60px;
}

.reservedExploration {
  position: absolute;
  bottom: 60px;
  right: 60px;
}
