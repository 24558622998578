.spectrumContainer {
  position: relative;
  width: auto;
  height: 90px;
  padding: 0 24px;
  border-radius: 8px;
  border: 1px solid #424242;
  background: rgba(53, 53, 53, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}

.spectrumContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 40px 100px;
  flex-wrap: wrap;
  font:
    700 28px/1 Barlow,
    sans-serif;
  text-transform: uppercase;
  margin-top: 17px;
}

.spectrumTitle {
  font-size: 24px;
}

.spectrumBar {
  margin-top: 16px;
  height: 8px;
  width: 100%;
  border-radius: 50px;
}

@media (max-width: 991px) {
  .spectrumContainer {
    padding: 0 20px;
  }
}
