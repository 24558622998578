.cardWrapper {
  margin: 80px 0;
}

.container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.heading {
  width: 940px;
  max-width: 100%;
  text-align: center;
  font:
    600 32px/32px Work Sans,
    sans-serif;
  margin: 70px 0 0;
}

.cardsContainer {
  display: flex;
  margin-top: 10px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  width: 75%;
}

@media (max-width: 991px) {
  .heading {
    max-width: 100%;
  }
}
