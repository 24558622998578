.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  margin: auto 0;
  padding: 25px 30px;
  height: 540px;
}

.cardBackground {
  border-radius: 16px;
  border: 1px solid #424242;
  background: rgba(53, 53, 53, 0.5);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  inset: 0;
  z-index: 0;
}

.content {
  position: relative;
  z-index: 0;
  width: 100%;
}

.titleSection {
  text-align: center;
}

.title {
  color: #fff;
  text-transform: uppercase;
  font:
    700 24px Barlow,
    sans-serif;
  margin: 0;
}

.divider {
  opacity: 0.2;
  background-color: #fff;
  height: 1px;
  margin-top: 16px;
  width: 100%;
  border: 1px solid #fff;
}

.brandsList {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}
