.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
  width: 100vw;
  padding-bottom: 30px;
  margin-top: 60px;
}

.heading {
  color: #fff;
  text-align: center;
  font:
    600 32px Work Sans,
    sans-serif;
  padding: 0 20px;
  margin-top: 0px;
}

.imageContainer {
  position: relative;
  width: 100%;
}

.buttonContainer {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.categoryStructureChart {
  width: calc(100vw - ((100vw - 1224px) / 2));
  margin-left: calc((100vw - 1224px) / 2);
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}
