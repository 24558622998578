.statCard {
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background: #f5df42;
  border-radius: 16px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}

.cardIcon {
  width: 130px;
}

.statsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.statGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.amtIdxWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.amount {
  font-size: 48px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  line-height: 1;
  color: #000;
}

.index {
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 36px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
}

.label {
  font-size: 20px;
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 600;
  color: #000;
}

@media (max-width: 991px) {
  .statCard {
    padding: 20px;
  }

  .amount {
    font-size: 40px;
  }
}
