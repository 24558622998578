.introText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: 28px;
  color: #fff;
  line-height: 38px;
  width: 400px;
  padding: 20px 40px;
  min-height: 0;
}

.textWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin: 0;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}
