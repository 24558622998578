.categoryCard {
  position: relative;
  display: flex;
  min-height: 205px;
  width: 131px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px 1px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}

.categoryCard:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.categoryIcon {
  width: 62px;
  aspect-ratio: 0.73;
  object-fit: contain;
  z-index: 1;
}

.categoryTitle {
  margin-top: 16px;
  font:
    700 24px Barlow,
    sans-serif;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}

@media (max-width: 991px) {
  .categoryCard {
    padding: 0 20px;
  }
}
