.tableWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.header {
  border-radius: 16px 16px 0 0;
  height: 70px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font:
    600 24px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  border-radius: 0 0 16px 16px;
  background-color: #1d1d1d;
  padding: 20px;
  flex: 1;
}

.segmentContent {
  flex: 1;
}

.label {
  color: #fff;
  font:
    500 14px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  margin-bottom: 8px;
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font:
    500 18px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  margin-top: 0px;
}

.statsProgBarWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  justify-content: center;
}

.catStatWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.name {
  flex: 1;
  margin-right: 16px;
}

.stats {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 92px;
  justify-content: flex-end;
}

.percentage {
  flex: 1;
}

.index {
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
}

@media (max-width: 991px) {
  .tableWrapper,
  .header,
  .content {
    max-width: 100%;
  }
}
