.chartCard {
  border-radius: 16px;
  background-color: #1d1d1d;
  height: 450px;
  flex: 1;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font:
    700 36px/1 Barlow,
    sans-serif;
}

.chartTitle {
  padding: 20px 0;
}

.divider {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin: 0 auto;
}

.chartContent {
  display: flex;
  margin: 16px 0 32px;
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 500;
  justify-content: center;
}

@media (max-width: 991px) {
  .chartCard {
    max-width: 100%;
  }

  .chartHeader {
    padding: 0 20px;
  }

  .chartContent {
    max-width: 100%;
    padding: 0 20px;
  }
}
