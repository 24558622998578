.calloutSection {
  display: flex;
  flex-direction: column;
  position: relative;
  aspect-ratio: 0.838;
  min-width: 240px;
  align-items: start;
  color: #f5df42;
  text-transform: uppercase;
  justify-content: flex-end;
  flex: 1;
  flex-basis: 24px;
  padding: 24px;
  font:
    700 36px Barlow,
    sans-serif;
}

.calloutImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.7;
}

.calloutText {
  position: relative;
  border-radius: 16px;
}
