.testimonialCard {
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
  height: 380px;
}

@media (max-width: 991px) {
  .quoteContent {
    padding: 0 20px;
  }

  .quoteText {
    margin-top: 40px;
  }

  .authorInfo {
    margin-top: 40px;
  }

  .calloutSection {
    padding: 0 20px;
  }
}
