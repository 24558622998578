.content {
  border-radius: 25px;
  background: #1d1d1d;
  display: flex;
  width: 1224px;
  height: 600px;
  padding: 30px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
