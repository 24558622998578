.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px;
  width: 1224px;
}

.header {
  align-self: flex-start;
  display: flex;
  max-height: 104px;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
}

.title {
  font:
    700 48px/36px Barlow,
    sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
}

.subtitle {
  font:
    600 24px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  margin-top: 20px;
}

.testimonialGrid {
  display: flex;
  margin-top: 32px;
  max-width: 100%;
  width: 1224px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
  }

  .title {
    font-size: 40px;
  }

  .subtitle {
    max-width: 100%;
  }
}
