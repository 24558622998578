@use "theme";


.custom-tooltip{
  font-size: 16px !important;
  padding: 10px !important;
  text-align: center !important;
  @media screen and (width < 100rem) {
    font-size: 13px !important;
 }
}
.slider-thumb-main-box{
  .selected-slider-thumb {
    width: 1.7rem;
    height: 1.4rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Zuume-Bold;
    &.non-selected-thumb {
      background: var(
        --persona-colors-outgoing-familiar-carefree-fun-lovers,
        theme.$grey-700
      ) !important;
      color: theme.$grey-300;
    }
  }
  .Fram_PSGMT_8{
    background-color: #8FE8BD;
  }
  .Fram_PSGMT_9{
    background-color: #79D271;
  }
  .Fram_PSGMT_2{
    background-color: #B983FF;
  }
  .Fram_PSGMT_3{
    background-color: #ADA1F8;
  }
  .Fram_PSGMT_11{
    background-color: #A1E8F8;
  }
  .Fram_PSGMT_12{
    background-color: #83BCFF;
  }
  .Fram_PSGMT_5{
    background-color: #FFC759;
  }
  .Fram_PSGMT_6{
    background-color: #FF8811;
  }
}

.slider-main-div{
  .slider-heading-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.625rem;
    flex: 1 0 0;
    align-self: stretch;
    text-align: center;
    font-family: Inter-Black;
    font-size: 15px;
    font-style: normal;
    line-height: 150%; /* 22.5px */
    letter-spacing: 0.45px;
    text-transform: uppercase;
    @media screen and (width < 100rem) {
      font-size: 12px;
    }
  }
}

.sliderstartvalue {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  .sliderstartvalue-typo1 {
    font-family: Inter-Bold;
    font-size: 15px;
    font-style: normal;
    line-height: 100%; /* 15px */
    letter-spacing: 0.5px;
    @media screen and (width < 100rem) {
      font-size: 12px;
   }
  }
}
.custom-thumb {
  color: theme.$black;
}

.custom-mark {
  height: 12px !important;
}
.notable-slider {
  width: 100%;
  display: flex;
  height: 4px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.5);
}
