.themeCard {
  display: flex;
  font-size: 28px;
  color: #fff;
  line-height: 38px;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  height: 300px;
  width: 300px;
}

.cardTitle {
  padding: 24px;
}

@media (max-width: 991px) {
  .themeCard {
    padding: 0 20px;
  }
}
