.segmentsContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 40px auto;
  width: 1340px;
}

.title {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font:
    700 48px Barlow,
    sans-serif;
}

.grid {
  align-self: center;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.segmentCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 122px;
  height: auto;
  margin: auto 0;
}

.segmentCard:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.segmentCardButton {
  border: none;
  background: none;
}

@media (max-width: 991px) {
  .title {
    max-width: 100%;
    font-size: 40px;
  }

  .grid {
    max-width: 100%;
    margin-top: 40px;
  }
}
