.containerWrapper {
  background: linear-gradient(180deg, #14232f 0%, rgba(0, 0, 0, 0) 99.22%);
  width: 100vw;
  padding-top: 100px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 1224px;
  margin: 0 auto;
  gap: 20px;
}

.header {
  color: #fff;
  text-transform: uppercase;
  font:
    800 75px/1 Barlow,
    sans-serif;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
}

.line {
  background-color: #f6e003;
  min-height: 4px;
  max-width: 100%;
  width: 200px;
  border: 4px solid #f6e003;
}

.title {
  margin: 60px 0;
  font-size: 75px;
}

.buttonGroup {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
}

.personaSection {
  display: flex;
  align-items: start;
}

.personaContent {
  display: flex;
  gap: 20px;
}

.highlightText {
  color: #f5df42;
}

.statsGrid {
  display: flex;
  gap: 20px;
}

.images {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.journeyImage {
  border-radius: 16px;
  min-width: 500px;
  flex: 1;
}

@media (max-width: 991px) {
  .container {
    padding: 100px 20px;
  }

  .header {
    max-width: 100%;
    padding-right: 20px;
    font-size: 40px;
  }

  .titleWrapper {
    max-width: 100%;
    font-size: 40px;
  }

  .title {
    max-width: 100%;
    font-size: 40px;
    margin-top: 40px;
  }

  .buttonGroup {
    max-width: 100%;
    margin-top: 40px;
  }

  .personaSection {
    max-width: 100%;
  }

  .personaContent {
    max-width: 100%;
  }

  .statsSection {
    max-width: 100%;
  }

  .journeyImage {
    max-width: 100%;
  }
}
