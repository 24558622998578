.statContainer {
  display: flex;
  flex-direction: column;
  width: 166px;
}

.statCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 166px;
}

.statInnerCircle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  width: 152px;
  height: 152px;
}

.statContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statValue {
  font:
    48px Barlow,
    sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.statLabel {
  letter-spacing: 0.1px;
  font:
    14px Barlow,
    sans-serif;
  font-weight: 700;
}

@media (max-width: 991px) {
  .statInnerCircle {
    padding: 0 20px;
  }

  .statValue {
    font-size: 40px;
  }
}
