.card {
  border-radius: 16px;
  position: relative;
  display: flex;
  min-height: 95px;
  width: 200px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0;
  margin-left: 7px;
  z-index: 0;
}

.cardBackground {
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  inset: 0;
  z-index: 0;
}

.content {
  z-index: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.value {
  text-transform: uppercase;
  align-self: center;
  font:
    48px Barlow,
    sans-serif;
  font-weight: 700;
}

.label {
  font:
    20px/45px Inter,
    sans-serif;
  text-align: center;
  font-weight: 700;
}

@media (max-width: 991px) {
  .card {
    padding: 0 20px;
  }

  .value {
    font-size: 40px;
  }
}
