.statsCard {
  display: flex;
  color: #fff;
  padding: 24px 0;
  width: 1224px;
  margin: 0 auto;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 500px;
  margin: auto 0;
  font:
    600 28px/42px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.icon {
  margin-top: -7px;
}

.title {
  align-self: stretch;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 740px;
}

.statValue {
  text-transform: uppercase;
  font:
    800 48px/1 Barlow,
    sans-serif;
}

.description {
  margin-top: 10px;
  font:
    600 20px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  max-width: 675px;
}

@media (max-width: 991px) {
  .statsCard {
    padding: 0 20px;
  }

  .cardBackground {
    max-width: 100%;
  }

  .titleWrapper {
    max-width: 100%;
  }

  .title {
    max-width: 100%;
  }

  .contentWrapper {
    max-width: 100%;
  }

  .statValue {
    max-width: 100%;
    font-size: 40px;
  }

  .description {
    max-width: 100%;
  }
}
