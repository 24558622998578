.button {
  align-self: stretch;
  border-radius: 512px;
  background-color: rgba(246, 224, 3, 1);
  min-width: 240px;
  min-height: 33px;
  gap: 8px;
  width: 253px;
  padding: 0 16px;
  color: #141414;
  font:
    500 16px Work Sans,
    sans-serif;
  border: none;
  cursor: pointer;
}

.button:hover {
  opacity: 0.9;
}

.button:focus {
  outline: 2px solid #fff;
  outline-offset: 2px;
}
