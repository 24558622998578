.statGroup {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stat {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  justify-content: center;
}

.value {
  color: #000;
  text-transform: uppercase;
  font:
    700 48px/1 Barlow,
    sans-serif;
  z-index: 0;
}

.tag {
  border-radius: 4px;
  color: #fff;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font:
    600 28px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  z-index: 0;
}

.description {
  color: #000;
  font:
    500 18px/26px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  margin-top: 15px;
  z-index: 0;
}

@media (max-width: 991px) {
  .stat {
    white-space: initial;
  }

  .value {
    font-size: 40px;
  }
}
