.statCard {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: center;
  width: 345px;
  font:
    600 28px/38px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  border-radius: 16px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}

.cardText {
  position: relative;
  z-index: 0;
  margin: 0 30px;
}

@media (max-width: 991px) {
  .statCard {
    padding: 20px;
  }
}
