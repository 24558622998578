.containerWrapper {
  background: linear-gradient(180deg, #14232f 0%, rgba(0, 0, 0, 0) 99.22%);
  width: 100vw;
  padding-top: 100px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 1224px;
  margin: 0 auto;
}

.header {
  display: flex;
}

.info {
  width: 700px;
}

.line {
  background-color: #f6e003;
  min-height: 4px;
  width: 200px;
  border: 4px solid #f6e003;
}

.content {
  margin-top: 40px;
}

.title {
  color: #fff;
  margin: 0;
  font-size: 75px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
}

.description {
  color: #fff;
  margin-top: 20px;
  font:
    600 28px/38px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  width: 95%;
}

.highlight {
  color: #f5df42;
}

.buttonGroup {
  display: flex;
  width: 600px;
  gap: 24px;
}

.button {
  border-radius: 10px;
  background-color: #f5df42;
  flex-grow: 1;
  padding: 16px 0px;
  font-size: 24px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
}

.statsContainer {
  border-radius: 16px;
  background-color: #1d1d1d;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  padding: 25px 20px;
}

.circleStats {
  display: flex;
  gap: 40px 80px;
}

.metricStats {
  display: flex;
  margin-top: 40px;
  gap: 40px 20px;
}

.footnote {
  color: #fff;
  text-align: right;
  margin: 20px 0 -20px;
  font:
    italic 500 14px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.themeCards {
  display: flex;
  margin-top: 40px;
  gap: 20px;
}

.themeCardContainer {
  flex: 1;
  min-width: 0;
  display: flex;
}

.footer {
  display: flex;
  min-height: 100px;
  flex-direction: column;
  align-items: start;
  color: #fff;
  justify-content: center;
  font:
    400 24px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

.sourceInfo {
  display: flex;
  gap: 8px;
}

.source {
  font-style: italic;
}

@media (max-width: 991px) {
  .container {
    padding: 100px 20px;
  }

  .header {
    max-width: 100%;
  }

  .info {
    max-width: 100%;
  }

  .content {
    max-width: 100%;
  }

  .titleSection {
    max-width: 100%;
  }

  .title {
    font-size: 40px;
  }

  .description {
    max-width: 100%;
  }

  .button {
    padding: 0 20px;
  }

  .statsContainer {
    max-width: 100%;
  }

  .circleStats {
    max-width: 100%;
  }

  .metricStats {
    max-width: 100%;
  }

  .footnote {
    max-width: 100%;
  }

  .themeCards {
    max-width: 100%;
  }

  .sourceInfo {
    max-width: 100%;
  }

  .source {
    max-width: 100%;
  }
}
