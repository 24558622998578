.patternCard {
  position: relative;
  flex: 1 1 calc((100% - (26px * 3)) / 4);
  min-width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.cardBackground {
  position: absolute;
  inset: 0;
  border-radius: 16px;
  z-index: 0;
}

.cardText {
  text-align: center;
  font:
    700 32px Barlow,
    sans-serif;
  z-index: 0;
}

@media (max-width: 991px) {
  .patternCard {
    padding: 0 20px;
  }
}
