.background {
  width: 100%;
  background: linear-gradient(180deg, #14232f 0%, rgba(0, 0, 0, 0) 23.47%);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 1224px;
  padding: 80px 0;
}

.content {
  display: flex;
  min-height: 186px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.header {
  display: flex;
  align-items: flex-end;
  min-width: 240px;
  flex-direction: column;
  color: #fff;
  white-space: nowrap;
  text-transform: uppercase;
  justify-content: flex-end;
  margin: 0;
  font:
    800 75px/1 Barlow,
    sans-serif;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: start;
}

.yellowLine {
  background-color: #f6e003;
  min-height: 4px;
  max-width: 100%;
  width: 200px;
  border: 4px solid #f6e003;
}

.title {
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 75px;
}

.downloadsList {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  color: #fff;
  width: 600px;
  margin: auto 0;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px 100px;
  }

  .content {
    max-width: 100%;
  }

  .header {
    max-width: 100%;
    font-size: 40px;
    white-space: initial;
  }

  .titleWrapper {
    max-width: 100%;
    font-size: 40px;
    white-space: initial;
  }

  .title {
    max-width: 100%;
    font-size: 40px;
  }

  .downloadsList {
    max-width: 100%;
  }
}
