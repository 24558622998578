.cardWrap {
  padding: 210px 0 70px;
}

.container {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.heading {
  width: 900px;
  max-width: 100%;
  text-align: center;
  font:
    600 32px/32px Work Sans,
    sans-serif;
  margin: 0 0 30px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.shopperImage {
  width: 1200px;
  object-fit: cover;
  margin: 0 auto;
}
