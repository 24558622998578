.statsCard {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 60px 0;
  flex-direction: column;
  font-family: Barlow, sans-serif;
}

.statsTitle {
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}

.highlightedText {
  text-decoration: underline;
  color: #f5df42;
}

.statsContent {
  display: flex;
  margin-top: 60px;
  width: 970px;
  max-width: 100%;
  align-items: center;
  gap: 40px 60px;
  text-transform: uppercase;
  justify-content: center;
  flex-wrap: wrap;
}

.statsImage {
  aspect-ratio: 2.87;
  object-fit: contain;
  object-position: center;
  width: 405px;
  align-self: stretch;
  min-width: 240px;
  margin: auto 0;
}

.sourceText {
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-feature-settings: 'salt' on;
  margin-top: 60px;
  font:
    italic 500 14px/1 Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .statsTitle {
    max-width: 100%;
  }

  .statsContent {
    margin-top: 40px;
  }

  .sourceText {
    max-width: 100%;
    margin-top: 40px;
  }
}
