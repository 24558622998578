.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  width: 100vw;
  margin-top: -150px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1224px;
}

.heading {
  align-self: center;
  max-width: 100%;
  font-size: 36px;
  line-height: normal;
  text-align: center;
  color: white;
  text-transform: uppercase;
  width: 1200px;
  margin: 60px auto 0;
}

.highlight {
  color: #fcd34d;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
  color: white;
}

.sections {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  max-width: 100%;
  font-size: 24px;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
  width: 1075px;
}

.strategySection {
  width: 525px;
}

.activationSection {
  width: 302px;
}

.retailSection {
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: center;
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  min-width: 240px;
}

.iconGrid {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .heading {
    max-width: 100%;
  }

  .content {
    margin-top: 40px;
    max-width: 100%;
  }

  .iconWrapper {
    max-width: 100%;
  }
}
