.basketComposition {
  width: 1224px;
  margin: 0 auto;
}

.subtitle {
  color: #f6e003;
  font-size: 24px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.title {
  color: #fff;
  font-size: 48px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 30px 0;
}

.content {
  display: flex;
  gap: 20px;
}

.mainStats {
  display: flex;
  gap: 20px;
  flex: 1;
}

.categorySection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.nonAlcHeader {
  display: flex;
  gap: 30px;
  border-radius: 16px;
  padding: 44px 24px;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nonAlcIcon {
  width: 94px;
  height: 94px;
  object-fit: contain;
}

.nonAlcTitle {
  font-size: 24px;
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 600;
}

.nonAlcAmount {
  font-size: 28px;
  font-family:
    Work Sans,
    sans-serif;
  font-weight: 600;
  padding-top: 10px;
}

.footer {
  color: #fff;
  font-family:
    Work Sans,
    sans-serif;
  font-style: italic;
  font-size: 14px;
  text-align: right;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .title {
    font-size: 40px;
  }

  .nonAlcHeader {
    padding: 20px;
  }
}
