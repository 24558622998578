.track {
  position: relative;
  display: flex;
}

.background {
  border-radius: 64px;
  background-color: rgba(255, 255, 255, 0.16);
  position: absolute;
  max-width: 100%;
}

.bar {
  display: flex;
  height: 24px;
  width: 100%;
  align-items: center;
}

.progress {
  border-radius: 64px;
  background-color: #f5df42;
  height: 4px;
}

.remaining {
  border-radius: 64px;
  background-color: rgba(255, 255, 255, 0.32);
  flex: 1;
  height: 4px;
}

@media (max-width: 991px) {
  .track,
  .bar {
    max-width: 100%;
  }
}
