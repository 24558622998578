.description {
  display: flex;
  color: #fff;
  width: 450px;
  height: 450px;
  font:
    600 28px/38px Work Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.descriptionText {
  align-self: center;
  padding: 45px;
}
