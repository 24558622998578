.factorCard {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cardHeader {
  display: flex;
  min-height: 100px;
  font-size: 28px;
  color: #000;
  text-transform: uppercase;
  line-height: 1;
  justify-content: center;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

.cardTitle {
  align-self: center;
  max-width: 380px;
}

.cardContent {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  flex: 1;
  gap: 10px;
}

.cardText {
  border-radius: 16px;
  min-height: 100px;
  background-color: #1d1d1d;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px 30px;
}
