.statBlock {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  min-height: 174px;
  flex-direction: column;
  justify-content: center;
  width: 266px;
  margin: auto 0;
}

.statNumber {
  color: #ffe800;
  font-feature-settings: 'salt' on;
  font-size: 75px;
  font-weight: 800;
  line-height: 1;
}

.statDescription {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .statNumber {
    font-size: 40px;
  }
}
